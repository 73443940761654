import 'promise-polyfill/src/polyfill'
import 'whatwg-fetch'
// import quicklink from 'quicklink/dist/quicklink.umd.js'
import flatpickr from 'flatpickr'
import Choices from 'choices.js'
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'

// Header
if (document.querySelector('.header')) {
    document
        .querySelector('.header__mobile-toggle')
        .addEventListener('click', function(e) {
            document
                .querySelector('.header__mobile-menu')
                .classList.toggle('is-active')
        })

    if (document.querySelector('.js-alerts-menu')) {
        document
            .querySelector('.js-alerts-menu')
            .addEventListener('click', function(e) {
                document
                    .getElementById('alerts-menu')
                    .classList.toggle('is-active')
            })
    }
}

// Autocomplete
if (document.querySelector('.form-select--autocomplete')) {
    document
        .querySelectorAll('.form-select--autocomplete')
        .forEach(function(el) {
            var choiceInput = new Choices(el, {
                itemSelectText: 'Click to select',
                removeItemButton: true,
                noChoicesText: 'No more options to select',
                classNames: {
                    containerInner: 'choices__inner form-select',
                },
            })

            if (el.classList.contains('form-select--autocomplete-users')) {
                choiceInput.setChoices(function() {
                    return fetch('/messages/autocomplete')
                        .then(function(response) {
                            return response.json()
                        })
                        .catch(function(err) {
                            console.error(err)
                        })
                })
            }

            if (el.classList.contains('form-select--autocomplete-groups')) {
                choiceInput.setChoices(function() {
                    return fetch('/groups/autocomplete')
                        .then(function(response) {
                            return response.json()
                        })
                        .catch(function(err) {
                            console.error(err)
                        })
                })
            }
        })
}

// Date/Time picker
if (document.querySelector('[class*="form-input--"]')) {
    // Date picker
    flatpickr('.form-input--datepicker', {
        altInput: true,
        altFormat: 'F j, Y',
        dateFormat: 'm/d/Y',
    })

    // function flatpickrYearDropdown(pluginConfig) {
    //     var today = new Date()
    //     var dd = String(today.getDate()).padStart(2, '0')
    //     var mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
    //     var yyyy = today.getFullYear()

    //     var defaultConfig = {
    //         text: '',
    //         theme: 'light',
    //         date: yyyy + '-' + mm + '-' + dd,
    //         yearStart: 100,
    //         yearEnd: 2,
    //     }

    //     var config = {}
    //     for (var key in defaultConfig) {
    //         config[key] =
    //             pluginConfig && pluginConfig[key] !== undefined
    //                 ? pluginConfig[key]
    //                 : defaultConfig[key]
    //     }

    //     var getYear = function(value) {
    //         console.log(value)
    //         var date = value.split('-')
    //         return parseInt(date[2], 10)
    //     }

    //     var currYear = new Date().getFullYear()
    //     var selectedYear = getYear(config.date)

    //     var yearDropdown = document.createElement('select')

    //     var createSelectElement = function(year) {
    //         var start = new Date().getFullYear() - config.yearStart
    //         var end = currYear + config.yearEnd

    //         for (var i = end; i >= start; i--) {
    //             var option = document.createElement('option')
    //             option.value = i
    //             option.text = i
    //             yearDropdown.appendChild(option)
    //         }
    //         yearDropdown.value = selectedYear
    //     }

    //     return function(fp) {
    //         fp.yearSelectContainer = fp._createElement(
    //             'div',
    //             'flatpickr-year-select ' + config.theme + 'Theme',
    //             config.text
    //         )

    //         fp.yearSelectContainer.tabIndex = -1
    //         createSelectElement(selectedYear)
    //         yearDropdown.addEventListener('change', function(evt) {
    //             var year = evt.target.options[evt.target.selectedIndex].value
    //             fp.changeYear(year)
    //         })

    //         fp.yearSelectContainer.append(yearDropdown)

    //         return {
    //             onReady: function onReady() {
    //                 var name = fp.monthNav.className
    //                 const yearInputCollection = fp.calendarContainer.getElementsByClassName(
    //                     name
    //                 )
    //                 const el = yearInputCollection[0]
    //                 el.parentNode.insertBefore(
    //                     fp.yearSelectContainer,
    //                     el.parentNode.firstChild
    //                 )
    //             },
    //         }
    //     }
    // }

    flatpickr('.form-input--consult', {
        // plugins: [flatpickrYearDropdown()],
        minuteIncrement: 15,
        enableTime: true,
        altInput: true,
        altFormat: 'F j, Y at h:i K',
        dateFormat: 'Y-m-d H:i',
        minDate: 'today',
        maxDate: new Date().fp_incr(29),
    })

    // Date picker
    flatpickr('.form-input--datepicker', {
        altInput: true,
        altFormat: 'F j, Y',
        dateFormat: 'm/d/Y',
    })

    // Date picker
    flatpickr('.form-input--dob', {
        altInput: true,
        altFormat: 'F j, Y',
        dateFormat: 'm/d/Y',
        maxDate: 'today',
        // allowInput: true,
    })
}

// File upload
var inputs = document.querySelectorAll('.form-file')
inputs.forEach(function(input) {
    var label = input.nextElementSibling,
        labelVal = label.innerHTML

    input.addEventListener('change', function(e) {
        var fileName = ''
        if (this.files && this.files.length > 1) {
            fileName = (
                this.getAttribute('data-multiple-caption') || ''
            ).replace('{count}', this.files.length)
        } else {
            fileName = e.target.value.split(/\\/gi).pop()
        }

        if (fileName) label.querySelector('span').innerHTML = fileName
        else label.innerHTML = labelVal
    })
})

// Tabs
var tabMobileControls = document.querySelector('.tabs__control--mobile select')
var tabControls = document.querySelectorAll('[id^="tab-control-"]')
var tabContents = document.querySelectorAll('[id^="tab-content-"]')

function resetTabs() {
    tabControls.forEach(function(el) {
        el.setAttribute('aria-selected', false)
        el.setAttribute('tabindex', -1)
    })
    tabContents.forEach(function(el) {
        el.setAttribute('aria-hidden', true)
    })
}

function activateTab(newIndex) {
    var newControl = document.getElementById('tab-control-' + newIndex)
    newControl.removeAttribute('tabindex')
    newControl.setAttribute('aria-selected', true)

    var newContent = document.getElementById('tab-content-' + newIndex)
    newContent.setAttribute('aria-hidden', false)

    // Update mobile controls
    if (tabMobileControls) {
        tabMobileControls.value = newIndex
    }
}

tabControls.forEach(function(el) {
    el.addEventListener('click', function(e) {
        e.preventDefault()

        var newIndex = this.getAttribute('href').replace(/[^0-9]/g, '')

        // Reset and update tabs
        resetTabs()
        activateTab(newIndex)
    })

    el.addEventListener('keydown', function(e) {
        var index = Array.prototype.indexOf.call(tabControls, e.currentTarget)

        // Determine key direction (left/up or right/down)
        var key = e.which
        var dir =
            key === 37 || key === 38
                ? index - 1
                : key === 39 || key === 40
                ? index + 1
                : null

        // Switch to new tab if it exists
        if (dir !== null) {
            e.preventDefault()

            // Find correct tab control
            var newIndex
            if (tabControls[dir]) {
                newIndex = dir
            } else {
                newIndex = dir === index - 1 ? tabControls.length - 1 : 0
            }

            // Reset and update tabs
            resetTabs()
            activateTab(newIndex + 1)

            // Focus new tab control
            tabControls[newIndex].focus()
        }
    })
})

if (tabMobileControls) {
    tabMobileControls.addEventListener('change', e => {
        resetTabs()
        activateTab(e.target.value)
    })
}

// Messages
if (document.getElementById('userList-mobile')) {
    var userListMobile = new Choices(
        document.getElementById('userList-mobile'),
        {
            itemSelectText: 'Click to select',
            removeItemButton: true,
            noChoicesText: 'No more options to select',
            classNames: {
                containerInner: 'choices__inner form-select',
            },
        }
    )

    $('#userList button').on('click', function(e) {
        userListMobile.setChoiceByValue($(this).attr('id'))
    })

    setTimeout(function() {
        var eid = window.localStorage.getItem('thd--active-message--eid')
        userListMobile.setChoiceByValue(eid)
    })
}

// Consults chat
if (document.querySelector('.consults-chat')) {
    document
        .querySelector('.toggle-chat')
        .addEventListener('click', function(e) {
            // Toggle chat
            document
                .querySelector('.consults-chat')
                .classList.toggle('is-active')

            // Scroll to bottom of chat
            var consultChat = document.querySelector(
                '.consults-chat .messages-list'
            )
            consultChat.scrollTop = consultChat.scrollHeight
        })
}

// Tippy
if (document.querySelector('[data-tippy-content]')) {
    tippy('[data-tippy-content]')
}
